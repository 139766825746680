import { Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuItem as MuiMenuItem } from "@mui/material";

import pages from "./../../../../constants/pages";
import { signOutMember } from "../../../../redux/slices/memberSlice";

const MenuItem = styled(MuiMenuItem)`
  min-width: 200px;
`;

export default function DropDownMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Fragment>
      <MenuItem onClick={() => navigate(pages.settings.route)}>
        Change Password
      </MenuItem>
      <MenuItem onClick={() => dispatch(signOutMember())}>Sign Out</MenuItem>
    </Fragment>
  );
}
